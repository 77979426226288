import React from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import RelatedStories from "../../components/RelatedStories";

const hero =
  "https://cms2.climacell.co/wp-content/uploads/2019/02/case-usta-hero.png";
const logo =
  "https://cms2.climacell.co/wp-content/uploads/2019/02/usta-logo-big.png";

export default props => (
  <Layout>
    <SEO
      title="USTA－全米オープンテニス | tomorrow.io®"
      description="During the tournament, the USTA Billie Jean King National Tennis Center in New York City stood up to a wide range of weather challenges."
      image={hero}
      pathname={props.location.pathname}
    />
    <section className="c-section-hero">
      <div className="row mx-0">
        <div
          className="col-lg-3 px-0"
          style={{
            minHeight: 600,
            background: `url(${hero}) no-repeat center / cover`,
          }}
        />
        <div className="col-lg-7 d-flex align-items-center">
          <div className="c-shifted-mob w-100 pb-4">
            <div className="d-flex align-items-center mb-5 pt-2 pt-lg-0">
              <img className="mr-3" width="140" src={logo} alt="" />
              <span>ケーススタディ</span>
            </div>
            <h1 className="h-2 mb-4">USTA – 全米オープンテニスのエース
              MicroWeather</h1>
            <p className="fs-lg">
              2018年の全米オープンテニスの舞台裏では、USTAビリー・ジーン・キング・ナショナル・テニス・センター（❝NTC❞）がアスリートや観客に、実用的で、行動を導き、分単位の気象予報と気づきを提供できるよう、最適な気象条件を提供することに専念していました。
            </p>
          </div>
        </div>
      </div>
      <div className="text-sideways d-none d-md-block">
        42°21'02.1" N 71°03'27.6" W
      </div>
    </section>

    <section className="c-section bg-navy text-white c-section-skew">
      <div className="container">
        <div className="row">
          <h2 className="col-md-3 h-3 mb-4">チャレンジ</h2>
          <div className="col-md-9 fs-lg">
            <p>
              一般的な屋外スポーツ競技は天候を管理することが欠かせませんが、NTCはもともと天候が不安定なロケーションであるのに加え、2018年は天候変化の激しいシーズンであった為、特に管理がむずかい年でした。
            </p>
            <p>
              全米オープンのトーナメントは、オペレーションとエンジニアチームが、安全阻害要因を除く為であったり、試合の不要な中断や遅延、中止を避けるため、降水量や極端な暑さの予報に応じて、重要な意思決定をしていました。
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="c-section">
      <div className="container">
        <div className="row mb-6">
          <h2 className="col-md-3 h-3 mb-4">
            2018年の全米オープンは厳しい天候に見舞われました
          </h2>
          <div className="col-md-9 fs-lg">
            <p>
              トーナメント中、ニューヨーク市のUSTAビリー・ジーン・キング・ナショナル・テニス・センターは、様々な難しい天候課題に直面しました。過去30年間で最高温度を記録したことや、尋常ではない熱波、断続的に続くにわか雨、止み終わらない雨などに見舞われました。このような状況は、アスリートや観客、スタッフに健康や安全性のリスクを引き起こし、トーナメント中に幾度となく天井を閉める必要がありました。
            </p>
            <p>
              トーナメントの決勝戦があった土曜日は、セリーナ・ウィリアムズと大坂なおみの最終戦前にルーフが閉じられていました。tomorrow.ioはNTCエグゼクティブとルーフ・エンジニアリング・チームににわか雨が近づいている事と、それに起因するリスクといったインサイトを提供しました。イベントはルーフを閉じることを決定し、閉じたままにしていました。これにより、女子決勝戦が天候による混乱が引き起こされないよう、確実な対応をしました。にわか雨はその夜、止む事なく、日曜日の決勝戦には断続的な雨に発展していました。男子決勝において、乾いたコートで安全にプレイができることを確実なものとする為、開催者さえもルーフを閉じたままにしておくことに同意しました。
            </p>
          </div>
        </div>

        <div className="row">
          <h2 className="col-md-3 h-3 mb-4">ソリューション</h2>
          <div className="col-md-9 fs-lg">
            <p>
              NTCは、他の方法では捕らえにくい気象データを活用するために、MicroWeatherの予測機能を導入しました。トーナメントはtomorrow.ioの分単位の気象予報を、オペレーションの意思決定に利用しました。
            </p>
            <ul>
              <li>
                史上初、男性の極端な暑さへの対策の導入
              </li>
              <li>
                トーナメント・ルールと、アスリートの状態の最適化を図った屋外の状態で、最大限にプレイできることを確実にすると同時に、コートで雨を防ぐために、雨が予想される時間に応じて、アーサー・アッシュスタジアムの開閉式屋根を準備すること。
              </li>
            </ul>
            <p>
              これによりアスリート、観客、そしてスタッフに安全な環境を提供し、トーナメントスケジュールに不要な遅延、中止、中断を少なくすることができました。
            </p>

            <div className="pt-5">
              <a className="btn btn-lg btn-primary" href="https://www.tomorrow.io/free-trial?bid=2">
                弊社の気象専門家にビジネスについて相談
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="c-section">
      <RelatedStories id="usta-microweather-case-study" />
    </section>
  </Layout>
);
